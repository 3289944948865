<template>
  <div id="login" style="min-height: calc(100vh - 20px);" tag="section">
    <b-row class="justify-content-center no-gutters">
      <b-col lg="12" class="d-flex align-items-center">
        <b-card no-body class="w-100">
          <b-row class="no-gutters" style="min-height: 100vh;" align-v="center">
            <b-col
                lg="6"
                class="d-md-flex align-items-center justify-content-center"
            >
              <b-carousel
                  id="carousel-no-animation"
                  style="text-shadow: 0px 0px 2px #000;"
                  no-animation
                  indicators
                  class="w-100"
              >
                <b-carousel-slide v-for="(items, index) in Banner" :key="index"
                                  :img-src="items.url"
                ></b-carousel-slide>
              </b-carousel>
            </b-col>
            <b-col lg="6" class="mt-2">

              <div class="mr-4 ml-4">
                <b-row align-h="center">
                  <b-col lg="6" sm="12">
                    <h3 class="mt-2">ยืนยันตัวตน</h3>
                  </b-col>
                  <b-col lg="5" sm="12" align="right">
                    <!--                    <language/>-->
                  </b-col>
                </b-row>
                <b-card no-body class="border mr-4 ml-4">
                  <form-wizard
                      :color="secondary_color"
                      :title="null"
                      :subtitle="null"
                      step-size="xs"
                  >
                    <tab-content title="ยืนยันตัวตน" icon="fas fa-edit" class="ml-2 mr-2">
                      <b-row align-h="center">
                        <b-col lg="8" sm="12">
                          <input-component
                              label-input="อีเมล"
                              v-model="$v.form.email.$model"
                              required
                              :state="validateState($v.form.email) "
                              :require-label="
                        validateState($v.form.email) !== null &&
                        !$v.form.email.required
                          ? $t('common.requiredField')
                          : ''
                      "
                          />
                        </b-col>
                      </b-row>
                      <b-row align-h="center">
                        <b-col sm="12" lg="8">
                          <div style="margin-top: 15px;">
                            <recaptcha-component
                                @verify="onVerify"
                                :resetToken="resetToken"
                                @token="getTokenReCap"
                            ></recaptcha-component>
                          </div>
                        </b-col>
                      </b-row>
                      <b-row align-h="center">
                        <b-col lg="8" sm="12">
                          <label style="color: #DF3F1E">{{errMessage}}</label>
                        </b-col>
                      </b-row>
                    </tab-content>
                    <tab-content title="OTP" icon="fas fa-user-circle" class="ml-2 mr-2">
                      <div align="center" v-if="!verified">
                        <verify-otp :phone="phone"
                                    :status-verify="stepfail"
                                    :success="success"
                                    @otp="verifyOTP"
                                    @resetstep="resetStep"
                                    @resend="reSendOTP"
                                    @token="getTokenReCap"
                                    :ref-code="ref_code"
                                    v-if="indexTab === 1"
                        />
                        <label style="color: #DF3F1E">หากหมายเลขโทรศัพท์ ไม่ถูกต้องกรุณาติดต่อแอดมิน</label>
                      </div>
                      <div align="center" v-else>
                        <i class="fas fa-check-circle display-4 mb-3 d-block text-success mt-3 mb-2"/>
                        <h3 class="mb-3">ยืนยันตัวตนสำเร็จ</h3>
                        <h5 class="mb-3">กรุณากดถัดไปเพื่อทำการตั้งค่ารหัสผ่าน</h5>
                      </div>
                    </tab-content>
                    <tab-content title="ตั้งรหัสผ่าน" icon="fas fa-asterisk" class="ml-2 mr-2">
                      <b-row align-h="center">
                        <b-col lg="6" sm="12">
                          <input-component
                              type="password"
                              icon="password"
                              required
                              :label-input="$t('account.from.password')"
                              v-model="$v.formReset.password.$model"
                              :state="validateState($v.formReset.password)"
                              :require-label="
                        validateState($v.formReset.password) !== null &&
                        !$v.formReset.password.required
                          ? $t('common.requiredField')
                          : ''
                      "
                          />
                        </b-col>
                        <b-col lg="6" sm="12">
                          <input-component
                              type="password"
                              icon="password"
                              required
                              :label-input="$t('account.from.confirmPassword')"
                              v-model="$v.formReset.confirm_password.$model"
                              :state="validateState($v.formReset.confirm_password)"
                              :require-label="
                        validateState($v.formReset.confirm_password) !== null &&
                        !$v.formReset.confirm_password.required
                          ? $t('common.requiredField')
                          : ''
                      "
                          />
                        </b-col>
                      </b-row>
                    </tab-content>
                    <template slot="footer" slot-scope="props">
                      <div class="wizard-footer-left">
                        <b-button v-if="props.activeTabIndex > 0" @click="backPage(props)">
                          <i class="fas fa-chevron-left"/>
                          ก่อนหน้า
                        </b-button>
                        <b-button
                            v-else
                            variant="outline"
                            @click="backToLogin()"
                        >
                          เข้าสู่ระบบ
                        </b-button>
                      </div>
                      <div class="wizard-footer-right">
                        <b-button v-if="!props.isLastStep" @click="nextPage(props)" variant="gradient"
                                  class="wizard-footer-right width-btn"
                                  :disabled="( $v.form.$invalid || !isVerify || is_loading_next_step) && (props.activeTabIndex !== 1 || !verified )"
                        >
                          ถัดไป
                          <b-spinner small label="Small Spinner" v-if="is_loading_next_step"></b-spinner>
                          <i class="fas fa-chevron-right" v-else/>
                        </b-button>
                        <b-button v-else @click="nextPage(props)" variant="gradient"
                                  class="wizard-footer-right width-btn"
                                  :disabled="$v.formReset.$invalid  ||is_loading_next_step "
                        >
                          บันทึก
                          <b-spinner small label="Small Spinner" v-if="is_loading_next_step"></b-spinner>
                          <i class="fas fa-chevron-right" v-else/>
                        </b-button>
                      </div>
                    </template>
                  </form-wizard>
                </b-card>
              </div>
              <div>
                <footer-blank/>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {FormWizard, TabContent} from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import {validationMixin} from 'vuelidate'
import InputComponent from '@/components/common/inputComponent'
import SelectComponent from '@/components/common/selectComponent'
import profileApi from '@/repository/profileApi'
import functionCommon from "@/common/functions";
import {
  required,
  minLength,
  email,
  sameAs,
} from 'vuelidate/lib/validators'
import Language from '@/components/common/Language'
import alert from '@/common/alert'
import {VueRecaptcha} from 'vue-recaptcha'
import recaptchaComponent from '../../components/common/recaptchaComponent.vue'
import VerifyOtp from "@/views/authentication/verifyOTP";
import VerifyEmail from "@/views/authentication/confirmEmail";
import {mapState} from 'vuex'
import FooterBlank from "@/layouts/full-layout/footer/FooterBlankLayout.vue";

export default {
  name: 'RegisterAuthen',
  components: {
    FooterBlank,
    VerifyEmail,
    VerifyOtp,
    Language,
    InputComponent,
    SelectComponent,
    VueRecaptcha,
    recaptchaComponent,
    FormWizard, TabContent
  },
  mixins: [validationMixin],
  created() {
    this.$i18n.locale = 'en'
  },
  data: () => ({
    form: {
      email: '',
      token: '',
    },
    formReset: {
      password: '',
      confirm_password: '',
    },
    phone: '',
    ref_code: "",
    transaction_id: '',
    stepfail: false,
    resetToken: false,
    setting: JSON.parse(localStorage.getItem('brand_setting')),
    is_next_step: false,
    is_loading_last_step: false,
    is_loading_next_step: false,
    errMessage: "",
    checkbox: false,
    isVerify: false,
    success: true,
    token: "",
    indexTab: null,
    verified: false
  }),
  validations: {
    checkbox: {
      required,
    },
    form: {
      email: {
        required,
        email,
      },
      token: {
        required,
      }
    },
    formReset: {
      password: {
        required,
        minLength: minLength(6),
      },
      confirm_password: {
        required,
        sameAsPassword: sameAs('password'),
      },
    }
  },
  methods: {
    validateState(validation) {
      const {$dirty, $error} = validation;
      return $dirty ? !$error : null;
    },
    onVerify(e) {
      this.errMessage = ''
      this.isVerify = e
    },
    getTokenReCap(e) {
      this.form.token = e
    },
    backToLogin() {
      this.$router.push('Login').catch(() => {
      })
    },
    backPage(props) {
      props.prevTab()
      this.indexTab = props.activeTabIndex - 1
    },
    nextPage(props) {
      this.indexTab = props.activeTabIndex + 1
      if (props.activeTabIndex === 0) {
        this.verified = false
        this.is_loading_next_step = true
        let data = {
          email: this.form.email,
          recaptcha_token: this.form.token
        }
        this.stepfail = false
        profileApi.otpOldUserSend(data).then(response => {
          if (response?.codeSYS === '001') {
            this.ref_code = response?.ref_code
            this.phone = response?.phone
            this.transaction_id = response?.transaction_id
            this.success = true
            this.indexTab = 1
            props.nextTab()
          } else {
            this.failed = true
            this.errMessage = response.message
            this.success = false
          }
        }).catch(err => {
          this.errMessage = err.response.data.message
        }).finally(() => {
          this.is_loading_next_step = false
          this.resetToken = false
        })
        this.resetToken = true
      } else if (props.activeTabIndex === 2) {
        this.verified = false
        this.is_loading_next_step = true

        this.stepfail = false
        const data = {
          password: this.formReset.password
        }
        profileApi.setNewPassword(data).then(response => {
          if (response?.codeSYS === '001') {
            this.$swal.fire({
              icon: 'success',
              title: 'ยืนยันตัวตนเสร็จสมบูรณ์',
              text: "กรุณาเข้าสู่ระบบใหม่เพื่อใช้งาน",
              showConfirmButton: false,
              timer: 1500
            })
            setTimeout(() =>
                this.$router.push('/Login').catch(() => {
                }), 1400)
          } else {
            alert.resetFailed(this, response.message)
          }
        }).catch(err => {
          alert.resetFailed(this, err.response.data.message)
        }).finally(() => {
          this.is_loading_next_step = false
        })
      } else {
        props.nextTab()
      }
    },
    resetStep() {
      this.stepfail = false
    },
    isLastStep(props) {
      if (props.isLastStep) {
        this.clearData()
        this.$emit('output-import', 'success');
      }
    },
    reSendOTP() {
      let data = {
        email: this.form.email,
        recaptcha_token: this.form.token
      }
      this.verified = false
      profileApi.otpOldUserSend(data).then(response => {
        if (response?.codeSYS === '001') {
          this.success = true
          this.ref_code = response?.ref_code
          this.phone = response?.phone
          this.transaction_id = response?.transaction_id
          this.token = response.transaction_id
          alert.resendSuccess(this)
        } else {
          this.success = false
          alert.resendFailed(this, response.message)
        }
      }).catch(err => {
        this.success = false
        alert.resendFailed(this, err.response.data.message)
      })
    },
    verifyOTP(otp) {
      this.loginSuccess = true
      const data = {
        transaction_id: this.transaction_id,
        otp_code: otp,
        ref_code: this.ref_code
      }
      profileApi.checkOTPOldUser(data).then((response) => {
        if (response.codeSYS === '001') {
          this.stepfail = false
          this.verified = true
        } else {
          this.stepfail = true
          this.verified = false
        }
      }).catch((err) => {
        this.stepfail = true
        this.verified = false
      })
    },
  },
  computed: {
    BrandSetting() {
      return this.$store.getters['profile/getBandSettingMedia']
    },
    Banner() {
      return this.$store.getters['profile/getBanner']
    },
    ...mapState({
      secondary_color: state => state.theme.secondaryColor,
    }),
  },
}
</script>

<style>
.polycy-link {
  color: var(--primary-color);
  cursor: pointer;
}

.wizard-tab-content {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
</style>
