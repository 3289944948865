<template>
  <div>
    <b-card class="email-content-card">
      <div style="display: flex; justify-content: center; ">
        <b-row>
          <b-col sm="12" lg="6" class="mb-5">
            <div style="margin-right: 20px;">
              <img src="../../assets/images/lock-screen.png" alt=""/>
            </div>
          </b-col>
          <b-col sm="12" lg="6" class="mb-5">
            <b-card v-if="isExpire" class="inner-card">
              <p style="color: #fff; font-size: 20px;">
                {{ $t('account.expire') }}
              </p>
              <div
                  @click="resendEmail"
                  style="
                  color: #7367f0;
                  cursor: pointer;
                  text-decoration: underline;
                "
              >
                {{ $t('account.emailAgian') }}
              </div>
              <div v-if="isResend" style="color: red; margin-bottom: 5px;">
                Email has been send.
              </div>
            </b-card>
            <b-card v-if="!isExpire" class="inner-card">
              <h3 class="text-white">
                {{ $t('account.confirm') }}
              </h3>
              <div align="center" class="mt-5">
                <b-button
                    class="mt-4"
                    pill
                    variant="gradient"
                    @click="() => { $router.push('Login') }">
                  {{ $t('account.toLogin') }}
                </b-button>
              </div>

            </b-card>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import profileApi from '@/repository/profileApi'

export default {
  name: 'verifyEmail',
  data() {
    return {
      isResend: false,
      isExpire: false,
    }
  },
  created() {
    this.checkExpireLink()
  },
  methods: {
    resendEmail() {
      setTimeout(() => {
        this.isResend = true
      }, 500)
    },
    checkExpireLink() {
      if (this.$route.query.token) {
        const data = {
          token: this.$route.query.token,
        }
        profileApi
            .checkVerifyEmail(data)
            .then((response) => {
              if (response.codeSYS === '001' && response.allow_verify) {
                profileApi
                    .verifyEmail(data.token)
                    .then((res) => {
                      if (res.codeSYS === '001' && res.success) {
                        this.isExpire = false
                        setTimeout(() => {
                          this.$router.push('Login')
                        }, 3000)
                      }
                    })
                    .catch((err) => {
                      this.isExpire = true
                    })
              }
            })
            .catch((err) => {
              this.isExpire = true
            })
      } else {
        this.$router.push('Login').catch(() => {
        })
      }
    },
  },
}
</script>

<style scoped>
.email-content-card {
  display: block;
  max-width: 700px;
  padding: 40px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.inner-card {
  width: 320px;
  height: 100%;
  background-color: rgb(175, 175, 175);
}

.email-address {
  background-color: #fff;
  height: 40px;
  border-radius: 5px;
  padding: 10px;
  display: flex;
  align-items: center;
  color: #003adc;
  margin-bottom: 10px;
}
</style>
